    import React, { Component } from 'react';
    import logoDark from './logo-t.png';
    import logoWhite from './character.png';
    import './App.scss';

    class App extends Component {
      constructor(props) {
        super(props);

        this.state = {
          schema: 'dark'
        }
        this.changeSchema = this.changeSchema.bind(this);
      }

      componentDidMount() {
        this._isMounted = true;
        const {schema} = this.state;
        const storageTheme = localStorage.getItem('theme');
        // console.log(storageTheme);
       if (this._isMounted) {
        if(!storageTheme) {
          localStorage.setItem('theme', schema);
        } else {
          this.setState({
            schema: storageTheme
          });
        }
       }
      }

      componentWillMount() {
        this._isMounted = false;
      }

      changeSchema(prop) {
        const storageTheme = localStorage.getItem('theme');

        if (prop !== storageTheme) {
          localStorage.setItem('theme', prop);
        }  

        this.setState({
          schema: prop
        });
      }

      render() {
        const {schema} = this.state;
        
        return (
          <div className={'App schema schema--'+schema}>
            <header className="App-header">
            <div className="change-schema">  
          <div onClick={() => this.changeSchema('light')} className="btn btn--light">light</div>
          <div onClick={() => this.changeSchema('dark')} className="btn btn--dark">dark</div>
          </div>
              <img src={(schema === 'light' ? logoWhite : logoDark)} className="App-logo" alt="logo" />
              <p className="text text--trick">
                WAITING FOR LIGHTS
              </p>
              <p className="text text--say">
                ^%$#$^-&&-*$$^$#
              </p>
            </header>
          </div>
        );
      }
    }

    export default App;
